@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Noto Sans'), url(fonts/NotoSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Noto Sans Medium'),
    url(fonts/NotoSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Noto Sans SemiBold'),
    url(fonts/NotoSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Noto Sans Bold'),
    url(fonts/NotoSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Inconsolata Regular'), local('Inconsolata-Regular'),
    url(https://fonts.gstatic.com/s/inconsolata/v18/QldKNThLqRwH-OJ1UHjlKGlZ5qhExfHw.woff2)
      format('woff2');
}

@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Inconsolata Bold'), local('Inconsolata-Bold'),
    url(https://fonts.gstatic.com/s/inconsolata/v18/QldXNThLqRwH-OJ1UHjlKGHiw71p5_zaDpwm.woff2)
      format('woff2');
}

